import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        user,
    },
    getters: {
        userinfo: (state) => state.user.userinfo,
        token: (state) => state.user.token,
    },
});
