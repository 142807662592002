import router from './router/index';
import { getToken, getRefreshToken } from '@/utils/auth';
import store from './store';

const WHITE_LIST = ['/login'];

router.beforeEach(async (to, from, next) => {
    const token = getToken();
    const refreshToken = getRefreshToken();

    if (token) {
        store.commit('SET_TOKEN', token); // 将 token 存入 vuex
        store.commit('SET_REFRESH_TOKEN', refreshToken); // 将 refreshToken 存入 vuex

        // 设置 title
        if (to.meta.title) {
            document.title = to.meta.title;
        }
        next();
    } else {
        if (WHITE_LIST.indexOf(to.path) > -1) {
            next();
        } else {
            // 由于目前限制用户输入手机号，入口务必携带phone
            next(`/login?from=${encodeURIComponent(location.href)}&phone=${to.query.phone}`);
        }
    }
});
