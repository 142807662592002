/**
 * 用户相关状态管理
 */

import { postCaptchaLogin, postPasswordLogin } from '@/api/auth';
import { setToken, removeToken, setRefreshToken, removeRefreshToken } from '@/utils/auth';
import { Toast } from 'vant';
const user = {
    state: {
        userinfo: null,
        refreshToken: '',
        token: '',
    },
    mutations: {
        SET_INFO: (state, info) => {
            state.info = info;
        },
        SET_TOKEN: (state, token) => {
            state.token = token;
        },
        SET_REFRESH_TOKEN: (state, refreshToken) => {
            state.refreshToken = refreshToken;
        },
    },
    actions: {
        // 验证码登录
        LoginByCaptcha: ({ commit }, { phone, code, token }) => {
            return new Promise((resolve) => {
                postCaptchaLogin(phone, code, token).then(([response, error]) => {
                    if (!error) {
                        const { access_token: token, refresh_token: refreshToken } = response.data;

                        setToken(token);
                        setRefreshToken(refreshToken);
                        commit('SET_TOKEN', token);
                        commit('SET_REFRESH_TOKEN', refreshToken);
                        resolve();
                    } else {
                        Toast(error.msg);
                    }
                });
            });
        },
        // 密码登录
        LoginByPassword: ({ commit }, { phone, password }) => {
            return new Promise((resolve) => {
                postPasswordLogin(phone, password).then(([response, error]) => {
                    if (!error) {
                        const { access_token: token, refresh_token: refreshToken } = response.data;

                        setToken(token);
                        setRefreshToken(refreshToken);
                        commit('SET_TOKEN', token);
                        commit('SET_REFRESH_TOKEN', refreshToken);
                        resolve();
                    } else {
                        Toast(error.msg);
                    }
                });
            });
        },
        RemoveToken: ({ commit }) => {
            return new Promise((resolve) => {
                removeToken();
                removeRefreshToken();
                commit('SET_TOKEN', '');
                commit('SET_INFO', '');
                commit('SET_REFRESH_TOKEN', '');
                resolve();
            });
        },
    },
};

export default user;
