/**
 * @param {String | Number | Date} data 要格式化的时间，如时间戳，日期
 * @param {String} format 要转换的日期格式，如 ‘yyyy-MM-dd hh:mm:ss’
 * */
export function dateFormat(date, format) {
    date instanceof Date || (date = new Date(date));
    const f = {
        'y+': date.getFullYear(),
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds(),
    };
    let fd;
    for (let o in f) {
        if (new RegExp(o).test(format)) {
            fd = RegExp.lastMatch;
            format = format.replace(
                fd,
                o === 'y+'
                    ? f[o].toString().slice(-fd.length)
                    : fd.length === 1
                    ? f[o]
                    : (f[o] + 100).toString().slice(-fd.length)
            );
        }
    }
    return format;
}

/**
 * 获取 URL 中的文件名
 * @param {String} url 被解析的url
 */
export function formatFileName(url) {
    if (!url) return '';
    const arr = url.split('/');
    let fileName = arr[arr.length - 1];
    return fileName;
}

/**
 * 删除对象内的空白字段 包括空字符串 null undefined
 */
