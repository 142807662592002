import axios from 'axios';
// import Vue from 'vue';
import store from '@/store';

const service = axios.create({
    baseURL: process.env.VUE_APP_API,
    timeout: 3000,
});

service.interceptors.request.use(
    (config) => {
        config.headers['Content-Type'] = 'application/json;charset=utf-8';
        if (config.method === 'post') {
            config.data = JSON.stringify(config.data);
        }
        // 注入 token
        if (!config.headers?.Authorization && store.getters.token) {
            config.headers.Authorization = 'Bearer ' + store.getters.token;
        }
        return config;
    },
    (err) => {
        return [null, err];
    }
);

service.interceptors.response.use(
    (response) => {
        if (response.data.code === 300000) {
            return [response.data, null];
        } else if (response.data.code === 300004) {
            store.dispatch('RemoveToken').then(() => {
                location.reload();
            });
        } else {
            return [null, response.data];
        }
    },
    (err) => {
        return [null, err];
    }
);

export default service;
