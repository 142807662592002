/**
 * @description 任务相关路由
 */

export default [
    {
        path: '/proposal/detail/:taskNo/:code',
        name: 'proposal',
        meta: {
            title: '提案确认',
        },
        component: () => import('@/views/task/proposal'),
    },
    {
        path: '/proposal/note/:taskNo/:code',
        name: 'proposal-note',
        component: () => import('@/views/task/note'),
        meta: {
            title: '提案笔记',
        },
    },
    {
        path: '/proposal/final/:taskNo',
        name: '/proposal-final',
        meta: {
            title: '结项确认',
        },
        component: () => import('@/views/task/final'),
    },
];
