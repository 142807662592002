/**
 * @description auth 相关方法
 */

const TokenKey = process.env.VUE_APP_TOKEN_KEY;
const RefreshTokenKey = process.env.VUE_APP_REFRESH_TOKEN_KEY;

export function getToken() {
    return localStorage.getItem(TokenKey);
}

export function setToken(token) {
    return localStorage.setItem(TokenKey, token);
}

export function removeToken() {
    return localStorage.setItem(TokenKey, '');
}

export function getRefreshToken() {
    return localStorage.getItem(RefreshTokenKey);
}

export function setRefreshToken(token) {
    return localStorage.setItem(RefreshTokenKey, token);
}

export function removeRefreshToken() {
    return localStorage.setItem(RefreshTokenKey, '');
}
