<template>
    <div v-if="visible" class="result-container" :style="{ zIndex: zIndex }">
        <i class="result-icon iconfont icon-success"></i>
        <div class="result-title">
            {{ title }}
        </div>
        <div class="result-sub-title">
            <slot></slot>
        </div>
        <van-button round @click="handleConfirm">{{ confirmButtonText }}</van-button>
    </div>
</template>

<script>
export default {
    name: 'component-result',
    props: {
        visible: {
            type: Boolean,
            default: false,
        },

        title: {
            type: String,
            default: '',
        },

        confirmButtonText: {
            type: String,
            default: '',
            required: true,
        },

        confirmButtonHref: {
            type: String,
        },

        confirmButtonHandler: {
            type: Function,
        },

        zIndex: {
            type: [Number, String],
            default: '99',
        },
    },
    methods: {
        handleConfirm() {
            if (this.confirmButtonHandler) {
                this.confirmButtonHandler();
            } else {
                this.$router.push({
                    path: this.confirmButtonHref,
                    query: { phone: this.$route.query.phone },
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.result-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 2rem;

    .result-icon {
        font-size: 5.5rem;
        color: #8ac958;
        margin-bottom: 0.75rem;
    }
    .result-title {
        padding: 0 2rem;
        font-size: 1.5rem;
        color: #000;
        margin-bottom: 1.5rem;
    }
    .result-sub-title {
        color: #666;
        margin-bottom: 2rem;
        padding: 0 2rem;
        line-height: 1.8;
    }
    .van-button {
        width: 50%;
    }
}
</style>
