<template>
    <div id="app">
        <div id="nav"></div>
        <router-view :key="key" />
    </div>
</template>
<script>
export default {
    name: 'sada',
    computed: {
        key() {
            return this.$route.name !== undefined
                ? this.$route.name + Date.now()
                : this.$route + Date.now();
        },
    },
};
</script>
<style lang="scss"></style>
