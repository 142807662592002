/**
 * @description 验证相关接口管理
 */

import request from '@/utils/request';

// 获取验证码
export function postSendCaptcha(phone) {
    return request({
        url: '/v1/common/auth/captcha',
        method: 'post',
        data: {
            phone,
        },
    });
}

// 验证码登录
export function postCaptchaLogin(phone, code, token) {
    return request({
        url: '/v1/common/auth/login',
        method: 'post',
        data: {
            phone,
            code,
            token,
        },
        headers: {
            Authorization: 'Basic ' + process.env.VUE_APP_CLIENT_BASIC,
        },
    });
}

// 密码登录
export function postPasswordLogin(phone, password) {
    return request({
        url: '/v1/common/auth/login-password',
        method: 'post',
        data: {
            phone,
            password,
        },
        headers: {
            Authorization: 'Basic ' + process.env.VUE_APP_CLIENT_BASIC,
        },
    });
}
