import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import './styles/common.scss';
import './styles/iconfont.css';

import './utils/useVant';
import './utils/useFillOssPath';

import './filters';

import './permission';

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
