<template>
    <div class="home">
        <lk-result :subTitle="msg" :iconfont="icon">洛可可洛可可</lk-result>
    </div>
</template>

<script>
import LkResult from '@/components/LkResult.vue';
export default {
    name: 'Home',
    components: {
        LkResult,
    },
    data() {
        return {
            msg: '设计有限公司设计有限公司设计有限公司',
            icon: 'icon-color-success',
        };
    },
};
</script>
