import Vue from 'vue';
const context = require.context('./', false, /\.js/);

const regComponentName = /\.\/(.+)\.js/;

context.keys().forEach((item) => {
    if (item !== './index.js') {
        const directive = context(item);
        const directiveName = item.match(regComponentName)[1];
        Vue.filter(directiveName, directive.default);
    }
});
