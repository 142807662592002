import Vue from 'vue';

import {
    Form,
    Button,
    Field,
    Notify,
    Checkbox,
    CheckboxGroup,
    ImagePreview,
    Popup,
    Picker,
    Toast,
    Dialog,
} from 'vant';

Vue.use(Form);
Vue.use(Button);
Vue.use(Field);
Vue.use(Notify);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(ImagePreview);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Toast);
Vue.use(Dialog);

Toast.setDefaultOptions('loading', { forbidClick: true, duration: 0, overlay: true });
Dialog.setDefaultOptions({ confirmButtonColor: '#2d374d', cancelButtonColor: '#999' });
